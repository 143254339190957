import { useQuery } from '../common/hooks/apiClient';
import { useEffect } from 'react';
import BasePageLayout from '../common/components/BasePageLayout';
import { useRouter } from 'next/router';

export default function HomePage() {
  const [loaded, error, data] = useQuery(
    '/api/hotels/?items=1&includes=hasLibeoAPIConnexion,hasExthandActivated'
  );
  const router = useRouter();

  useEffect(() => {
    if (error || !loaded) {
      return;
    }

    if (data.items[0]) {
      router.push('/' + data.items[0].slug + '/contrats');
    }
  }, [loaded, error, data]);

  if (error) {
    return <p>erreur</p>;
  }

  if (!loaded) {
    return <p>chargement</p>;
  }

  return <BasePageLayout></BasePageLayout>;
}
